<template>
    <setting-layout :title="$t('pages.profile.main.followers')" :back="{ name: 'user.own.profile.view' }">
        <v-container class="pt-2">
            <template v-if="user.followers">                
                <v-row align="center" class="mt-4 item" v-for="(item, index) in user.followers.data" :key="index">
                    <v-col cols="2">
                        <router-link :to="{name: 'otherUser.profile.viewSlug', params: {slug: item.slug, from: $route.name, id: $route.params.id}}">
                            <profile-image
                                :src="item.image"
                                :username="item.name"
                            />
                        </router-link>
                    </v-col>
                    <v-col cols="10">
                        <div class="d-flex align-center">
                            <router-link :to="{name: 'otherUser.profile.viewSlug', params: {slug: item.slug, from: $route.name, id: $route.params.id}}">
                                {{ item.name }}
                            </router-link>
                            <verified-icon
                                v-if="item.isVerify"
                                :height="16" :width="16" class="ml-1" />
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";

export default {
    name: "Followers",
    components: {
        SettingLayout,
        ProfileImage,
        VerifiedIcon
    },
    computed: {
        user() {
            return this.$auth.user();
        }
    },
    mounted() {
        this.$store.dispatch('getOwnFollowers')
            .then(res => {
                this.$rQ.sud(this.$auth, 'followers', res)
                //this.$auth.user({...this.$auth.user(), followers: res})
            })
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Followers');
    }
}
</script>

<style scoped>
.profile-image {
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}

.unblock {
    color: #FE4811;
    font-size: 0.9em;
}

.item {
    background: #1A1A1A;
    border-radius: 18px;
}
</style>
